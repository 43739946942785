//User Reducer Types
export const AXIOS_REQUEST = 'AXIOS_REQUEST';
export const AXIOS_REQUEST_SUCCESS = 'AXIOS_REQUEST_SUCCESS';
export const AXIOS_REQUEST_FAILURE = 'AXIOS_REQUEST_FAILURE';
export const SET_VEHICLE_OFFENSE = 'SET_VEHICLE_OFFENSE';
export const SET_VEHICLE_OFFENSE_OBJECT = 'SET_VEHICLE_OFFENSE_OBJECT';
export const SET_VIOLATOR_STATE = 'SET_VIOLATOR_STATE';
export const SET_VIOLATOR_CODE = 'SET_VIOLATOR_CODE';
export const SET_VEHICLE_PAID_FINES = 'SET_VEHICLE_PAID_FINES';
export const SET_PAYMENT_INFO = 'SET_PAYMENT_INFO';
export const SET_COMMENT_OBJECT='SET_COMMENT_OBJECT';
export const SET_CONTEST_BY_REGNUMBER = 'SET_CONTEST_BY_REGNUMBER';
export const SET_HTTP_STATUS_CODE = 'SET_HTTP_STATUS_CODE';
export const SET_INFO_SIDEBAR = 'SET_INFO_SIDEBAR';