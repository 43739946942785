export const pageurl = {
    SEARCH_OFFENCE: '/search-offence',
    OFFENCE_LIST: '/offence-list',
    BILL_INVOICE: '/bill-invoice',
    BILL_CALLBACK: '/bill-callback',
    BILL_IDPAYMENT: '/bill-vehicle-offence',
    CONTEST_OFFENCE: '/contest-offence',
    VIEW_CONTEST_OFFENCE: '/view-contest-offence',
    PAGE_404: '/page-404',
    INSTRUCTIONS: '/instructions'
};

export const etraffica_baseurl = 'https://prod-etraffika.azurewebsites.net/api';

export const validEmailRegex = /\S+@\S+\.\S+/;
