import {
	AXIOS_REQUEST,
	AXIOS_REQUEST_FAILURE,
	AXIOS_REQUEST_SUCCESS,
	SET_VEHICLE_OFFENSE,
	SET_VEHICLE_OFFENSE_OBJECT,
	SET_VIOLATOR_STATE,
	SET_VIOLATOR_CODE,
	SET_VEHICLE_PAID_FINES,
	SET_PAYMENT_INFO,
	SET_COMMENT_OBJECT,
	SET_HTTP_STATUS_CODE,
	SET_CONTEST_BY_REGNUMBER,
	SET_INFO_SIDEBAR,
} from "../actions/types";

const initialState = {
	vehicleOffenses: [],
	vehicleOffenseObject: {},
	paymentInfo: {},
	loading: false,
	authenticated: false,
	errors: '',
	violatorState: [],
	violatorCode: '',
	paidFines: [],
	contestsByRegNumber: [],
	commentObject:{},
	infoSideBar: {},
	httpStatus: 0,
	action:''
};

const violatorReducer = function (state = initialState, action: any) {
	switch (action.type) {
		case AXIOS_REQUEST:
			return {
				...state,
				loading: true,
				action: action.payload
			};
		case SET_VEHICLE_OFFENSE:
			return {
				...state,
				vehicleOffenses: action.payload,
			};
		case SET_INFO_SIDEBAR:
			return {
				...state,
				infoSideBar: action.payload,
			};
		case SET_VEHICLE_OFFENSE_OBJECT:
			return {
				...state,
				vehicleOffenseObject: action.payload,
			};
		case SET_VIOLATOR_STATE:
			return {
				...state,
				violatorState: action.payload
			};
		case SET_VIOLATOR_CODE:
			return {
				...state,
				violatorCode: action.payload
			};
		case SET_COMMENT_OBJECT:
			return {
				...state,
				commentObject: action.payload
			};
		case SET_CONTEST_BY_REGNUMBER:
			return {
				...state,
				contestsByRegNumber: action.payload
			};
		case SET_PAYMENT_INFO:
			return {
				...state,
				paymentInfo: action.payload
			};
		case SET_VEHICLE_PAID_FINES:
			return {
				...state,
				paidFines: action.payload
			};
		case AXIOS_REQUEST_SUCCESS:
			return {
				...state,
				loading: false,
				errors: '',
				authenticated: true
			};
		case AXIOS_REQUEST_FAILURE:
			return {
				...state,
				data: action.payload,
				loading: false
			};
		case SET_HTTP_STATUS_CODE:
			return {
				...state,
				httpStatus: action.payload,
			};
		
		default:
			return state;
	};
};

export default violatorReducer;