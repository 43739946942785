import React from 'react';
import ReactDOM from 'react-dom';
import {Route, Switch, BrowserRouter as Router, Redirect} from 'react-router-dom';
import {Provider} from 'react-redux';
import store from './store';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Loadable from 'react-loadable';
import {pageurl} from './utils/constants';
// import Navbar from './components/navigations/navbar';
import * as serviceWorker from './serviceWorker';
import './index.scss';

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Montserrat',
  }
});


const BillPayment = Loadable({
  loader: () => import('./components/bill-payment/landing-page'),
  loading: () => <div>..Loading!</div>
});
const BillList = Loadable({
  loader: () => import('./components/bill-payment/dashboard'),
  loading: () => <div>..Loading!</div>
});
const BillInvoice = Loadable({
  loader: () => import('./components/bill-payment/invoice'),
  loading: () => <div>..Loading!</div>
});
const BillCallBack = Loadable({
  loader: () => import('./components/bill-payment/callback'),
  loading: () => <div>..Loading!</div>
});
const BillIdPayment = Loadable({
  loader: () => import('./components/offenceId'),
  loading: () => <div>..Loading!</div>
});
const ContestsOffence = Loadable({
  loader: () => import('./components/bill-payment/dashboard/contests/contest-offence'),
  loading: () => <div>..Loading!</div>
});
const ViewContests = Loadable({
  loader: () => import('./components/bill-payment/dashboard/contests/view-contest-offence'),
  loading: () => <div>..Loading!</div>
});
const Page404 = Loadable({
  loader: () => import('./utils/page-404'),
  loading: () => <div>..Loading!</div>
});
const Instructions = Loadable({
  loader: () => import('./components/bill-payment/dashboard/instructions/index'),
  loading: () => <div>..Loading!</div>
});

const Routes = () => (
  <Router>
    {/* <Navbar/> */}
    <Switch>
      <Route exact path="/"><Redirect to={pageurl.SEARCH_OFFENCE} /></Route>
      <Route exact path={`${pageurl.SEARCH_OFFENCE}/:id?/:regNumber?`} component={BillPayment}/>
      <Route exact path={pageurl.OFFENCE_LIST} component={BillList}/>
      <Route exact path={pageurl.BILL_INVOICE} component={BillInvoice}/>
      <Route exact path={pageurl.BILL_CALLBACK} component={BillCallBack}/>
      <Route exact path={`${pageurl.BILL_IDPAYMENT}/:offenceId`} component={BillIdPayment}/>
      <Route exact path={`${pageurl.CONTEST_OFFENCE}/:offenceId`} component={ContestsOffence}/>
      <Route exact path={pageurl.VIEW_CONTEST_OFFENCE} component={ViewContests}/>
      <Route exact path={pageurl.INSTRUCTIONS} component={Instructions}/>
      <Route exact path="*" component={Page404}/>
    </Switch>
  </Router>
);

ReactDOM.render(
  <Provider store={store}>
      <React.StrictMode>
        <ThemeProvider theme={theme}>
          <Routes />
        </ThemeProvider>
  </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
